$primary: #0b7fab;

@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';

.content img {
  width: 100%;
}

.embed-responsive {
  position: relative;
  padding-top: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
